@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.font-roboto {
  font-family: 'Roboto', sans-serif;
}




.profile-otp-input-style {
    width: 15% !important;
}

.view_all_class {
    position: absolute;
    right: 25px;
}

.crowsel_left {
    width: 43px;
    margin-top: 13px;
    margin-left: -16px;
}

.crowsel_right {
    width: 42px;
    margin-top: 13px;
    margin-right: -16px;
}

.mini_crowsel_left {
    width: 37px;
    margin-top: 24px;
    margin-left: -19px;
}

.mini_crowsel_right {
    width: 37px;
    margin-top: 24px;
    margin-right: -16px;
}



.newsletter-sec input[type="email"] {
    background: #fff;
    height: 43px;
    border: none;
    padding: 0 10px;
    width: 60%;
    border-radius: 18px;
    font-size: 13px;
  }
  
  .newsletter-sec input[type="submit"] {
    background: #673089;
    height: 37px;
    border: none;
    width: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 20px;
    line-height: 9px;
    margin-right: 3px;
  }




@media only screen and (max-width: 765px) and (min-width: 375px) {
    .experts__profile_mob_section{
        width: 106px;
    }

    .top_to_btm{
        margin-bottom: 51px;
    }

    .top_menu_mob{
        margin-top: 2px;
    }

    .SlideHight {
        min-height: 241px!important;
    }

    .owl-theme .owl-dots .owl-dot span {
        width: 5px!important;
        height: 5px!important;
        margin: 5px 3px!important;
    }

}


@media (orientation: landscape) {
    .SlideHight {
        min-height: 290px!important;
    }

    .top_to_btm {
        margin-bottom: 0px;
    }

}

@media only screen and (width:896px)  {
    .newsletter-sec input[type="submit"] {
        background: #673089;
        height: 37px;
        border: none;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 20px;
        line-height: 9px;
        margin-right: 3px;
    }
}

@media only screen and (width:768px)  {
    .newsletter-sec input[type="submit"] {
        background: #673089;
        height: 37px;
        border: none;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 20px;
        line-height: 9px;
        margin-right: 3px;
    }

    .SlideHight {
        min-height: 290px !important;
    }

    .top_to_btm{
        margin-bottom: 51px;
    }

    .top_menu_mob{
        margin-top: 2px;
    }
    
    .owl-theme .owl-dots .owl-dot span {
        width: 5px!important;
        height: 5px!important;
        margin: 5px 3px!important;
    }

    .download_btn{
        font-size: 13px;
    }

    .download_btn{
        font-size: 12px!important;
    }

    .tab_menu_margin ul li{
    }

    .tab_menu_margin li{
        margin-left: 20px!important;
    }
}



@media only screen and (width:820px)  {
    .newsletter-sec input[type="email"]{
        font-size: 9px!important;
    }

    .newsletter-sec input[type="submit"] {
        background: #673089;
        height: 37px;
        border: none;
        width: 40%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 9px;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 20px;
        line-height: 9px;
        margin-right: 3px;
    }
    
}

.truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}